import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NxModule } from '@nrwl/nx';
import { NgxStripeModule } from 'ngx-stripe';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import {
  AppLoadService,
  CoreModule,
  CustomRouterStateSerializer,
} from '@tenant/core';
import { DirectivesModule } from '@tenant/directives';
import { OtFormsModule } from '@tenant/forms';
import { IdleModeModule } from '@tenant/idle-mode';
import { PendingApprovalModalComponent } from '@tenant/modals';
import { OtModule, PipesModule } from '@tenant/ot';
import { OtGlobalModule } from '@tenant/ot-global';
import { TableModule } from '@tenant/table';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ApplicationsPaymentComponent } from './applications-payment/applications-payment.component';
import { AppComponent } from './app.component';
import { AppRouterModule } from './app.routes';
import { SentryErrorHandler } from './sentry-error-handler';
import { environment } from '../environments/environment';
import { metaReducers, reducers } from './reducers';
import { DashboardModule } from './dashboard/dashboard.module';
import { UsersnapComponent } from './usersnap/usersnap.component';
import { SharedModule } from './shared/shared.module';

const ENTRY_COMPONENTS = [PendingApprovalModalComponent];

const providers = [];

export function initApp(appLoadService: AppLoadService) {
  return () => appLoadService.initializeApp();
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NxModule.forRoot(),
    AppRouterModule,
    CommonModule,
    OtModule,
    DirectivesModule,
    TableModule,
    OtFormsModule,
    NgbModule,
    NgbModalModule,
    NgxPermissionsModule.forRoot(),
    OtGlobalModule.forRoot(),
    // IdleModeModule.forRoot({ idle: 30 * 60, timeout: 30, ping: 30 * 60 }),
    CoreModule.forRoot(),
    OtModule.forRoot(),
    InlineSVGModule.forRoot(),
    NgxStripeModule.forChild(environment.STRIPE_PUBLISHABLE_KEY),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
    PipesModule,
    DashboardModule,
    SharedModule
  ],
  declarations: [
    AppComponent,
    ApplicationsPaymentComponent,
    // UsersnapComponent,
    ENTRY_COMPONENTS,
  ],
  bootstrap: [AppComponent],
  providers: [
    providers,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppLoadService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
  ],
})
export class AppModule {}
