import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@tenant/directives';
import { OtFormsModule } from '@tenant/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { MenuBarWrapperComponent } from './menu-bar/menu-bar-wrapper/menu-bar-wrapper.component';
import { HeaderService } from './header/header.service';
import { NotificationsComponent } from './header/notifications/notifications.component';
import { TasksDropdownComponent } from './header/tasks-dropdown/tasks-dropdown.component';
import { WorkQueueDropdownComponent } from './header/work-queue-dropdown/work-queue-dropdown.component';
import { OverlayNotificationComponent } from './overlay-notifications/overlay-notification/overlay-notification.component';
import { OverlayNotificationsComponent } from './overlay-notifications/overlay-notifications.component';
import { OverlayNotificationsService } from './overlay-notifications/overlay-notifications.service';
import { PageTitleComponent } from './page-title/page-title.component';
import { UserBarComponent } from './user-bar/user-bar.component';
import { PipesModule } from '@tenant/ot';
import { SearchComponent } from './header/search/search.component';

const COMPONENTS = [
  UserBarComponent,
  OverlayNotificationComponent,
  OverlayNotificationsComponent,
  NotificationsComponent,
  WorkQueueDropdownComponent,
  TasksDropdownComponent,
  HeaderComponent,
  FooterComponent,
  MenuBarComponent,
  MenuBarWrapperComponent,
  SearchComponent,
];

@NgModule({
  imports: [
    CommonModule,
    InlineSVGModule,
    DirectivesModule,
    RouterModule,
    OtFormsModule,
    NgbTooltipModule,
    NgbPopoverModule,
    ClickOutsideModule,
    NgxPermissionsModule,
    PipesModule,
  ],
  declarations: [COMPONENTS, PageTitleComponent],
  exports: [COMPONENTS, PageTitleComponent],
})
export class OtGlobalModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: OtGlobalModule,
      providers: [OverlayNotificationsService, HeaderService],
    };
  }
}
