import { environment } from '../../apps/admin/src/environments/environment';

export class ApiConfig {
  public static base = `${environment.FULTONGRACE_URL}/api`;
  public static token = `${ApiConfig.base}/users/token-authenticate/`;
  public static tokenRefresh = `${ApiConfig.base}/users/token-refresh/`;
  public static tokenInfo = `${ApiConfig.base}/oauth/token/status`;
  public static userInfo = `${ApiConfig.base}/users/profile`;
  public static applications = `${ApiConfig.base}/application-form/`;
  public static countries = `${ApiConfig.base}/countries`;
  public static stats = `${ApiConfig.base}/stats`;
  public static workQueue = `${ApiConfig.base}/deals/work-queue`;
  public static dealCheckDuplicate = `${ApiConfig.base}/deals/check-duplicate`;
  public static billing = `${ApiConfig.base}/billing`;
  public static history = `${ApiConfig.base}/payment`;
  public static downloadPdf = `${ApiConfig.base}/pdf`;
  public static documents = `${ApiConfig.base}/documents`;
  public static contacts = `${ApiConfig.base}/contacts`;
  public static contactsBriefList = `${ApiConfig.base}/contacts/brief-list`;
  public static contactTypes = `${ApiConfig.base}/contact_types`;
  public static leadSource = `${ApiConfig.base}/lead-sources`;
  public static assignedAgents = `${ApiConfig.base}/users/ot-visible-users/`;
  public static agentsForDeals = `${ApiConfig.base}/users/agents-for-deals/`;
  public static assignedAgentsWithoutAdmins = `${ApiConfig.base}/users/ot-active-teamleads-and-agents/`;
  public static dealAssignedAgents = `${ApiConfig.base}/deals/agents`;
  public static listingImages = `${ApiConfig.base}/listing_images`;
  public static dealCreate = `${ApiConfig.base}/deal_forms`;
  public static deal = `${ApiConfig.base}/deals/`;
  public static workQueueDeal = `${ApiConfig.base}/work_queue/deals`;
  public static summary = `${ApiConfig.base}/stats/dashboard/deal-summary`;
  public static notes = `${ApiConfig.base}/notes`;
  public static tasks = `${ApiConfig.base}/tasks`;
  public static dealTasks = `${ApiConfig.base}/deals/tasks`;
  public static adminUsers = `${ApiConfig.base}/users`;
  public static adminTuCredentials = `${ApiConfig.base}/tu_credentials`;
  public static adminAgenciesReportStatistics = `${ApiConfig.base}/report_statistics`;
  public static applicantReports = `${ApiConfig.base}/applicant_reports`;
  public static search = `${ApiConfig.base}/search`;
  public static dealStatuses = `${ApiConfig.base}/deal_statuses`;
  public static dashTasks = `${ApiConfig.base}/stats/dashboard/my-tasks`;
  public static embeded = `/embed_form`;
  public static processApplications = `${ApiConfig.base}/process_applications`;
  public static userNotifications = `${ApiConfig.base}/notifications`;
  public static dealReason = `${ApiConfig.base}/deal_loss_reasons`;
  public static officeResources = `${ApiConfig.base}/resources`;
  public static resourceCategories = `${ApiConfig.base}/resource_categories`;
  public static documentStorage = `${ApiConfig.base}/storages`;
  public static defaultTasks = `${ApiConfig.base}/default_tasks`;
  public static transunion = `${ApiConfig.base}/transunion`;
  public static currentUserInfo = `${ApiConfig.base}/users/personal-info/base/`;
  public static teams = `${ApiConfig.base}/teams`;
  public static landlords = `${ApiConfig.base}/landlords`;
  public static applicationAgents = `${ApiConfig.base}/users/application-agents/`;
  public static stopImpersonation = `${ApiConfig.base}/stop-impersonation/`;
  public static companyLeaderboard = `${ApiConfig.base}/stats/reports/company-leaderboard`;
  public static myTeamRankings = `${ApiConfig.base}/stats/reports/my-team-rankings`;
  public static getSettings = `${ApiConfig.base}/settings/`;
  public static agentRole = `${ApiConfig.base}/stats/reports/agent-role`;
  public static leadSourceReport = `${ApiConfig.base}/stats/reports/lead-source`;
  public static productionDetail = `${ApiConfig.base}/stats/reports/production-detail`;
  public static paymentHistory = `${ApiConfig.base}/stats/reports/payment-history`;
  public static goalPlanning = `${ApiConfig.base}/stats/reports/goal-pacing`;
  public static annualGoals = `${ApiConfig.base}/annual-goals`;
  public static userAnnualGoals = (staffProfileId: number) =>
    `${ApiConfig.base}/user-annual-goals/${staffProfileId}`;
  public static clientsForDealContacts = `${ApiConfig.base}/clients/for-deal-contacts/`;
  public static listingsAddresses = `${ApiConfig.base}/listings-addresses`;
  public static currentUserPersonalInfo = `${ApiConfig.base}/users/personal-info/`;
  public static staffProfiles = `${ApiConfig.base}/staff-profiles`;
}
