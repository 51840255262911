import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from '../../../../../apps/admin/src/environments/environment';

@Injectable()
export class SessionService {
  private _impersonateState = new BehaviorSubject<boolean>(false);
  private _announcementMessage = new BehaviorSubject<string>('');

  get isLocal(): boolean {
    return window.location.href.includes('http://localhost:');
  }

  get impersonateState() {
    return this._impersonateState.asObservable();
  }

  get announcementMessage() {
    return this._announcementMessage.asObservable();
  }

  get state() {
    return this._loggedInState.asObservable();
  }

  get token() {
    return this._cookieService.get('access');
  }

  set token(value) {
    this._cookieService.put(
      'access',
      value,
      this.isLocal ? null : { domain: environment.DOMAIN }
    );
    localStorage.removeItem('last_action');
    this._loggedInState.next(this.isLoggedIn);
  }

  get refreshToken() {
    return this._cookieService.get('refresh');
  }

  set refreshToken(value) {
    if (!value) {
      this._cookieService.remove('refresh');
    } else {
      this._cookieService.put(
        'refresh',
        value,
        this.isLocal ? null : { domain: environment.DOMAIN }
      );
    }
  }

  get subdomain() {
    return this._cookieService.get('otSubdomain');
  }

  set subdomain(value) {
    this._cookieService.put('otSubdomain', value);
  }

  get impersonate() {
    const tmp = this._cookieService.get('impersonationid');
    if (!tmp) {
      return false;
    }
    return JSON.parse(tmp);
  }

  set impersonate(value) {
    if (!value) {
      this._cookieService.remove('impersonationid', {
        domain: environment.DOMAIN,
      });
    } else {
      this._cookieService.put('impersonationid', JSON.stringify(value), {
        domain: environment.DOMAIN,
      });
    }
    this._impersonateState.next(this.impersonate);
  }

  get isLoggedIn() {
    return !!this.token;
  }

  get adminId() {
    return this._cookieService.get('otAdminId');
  }

  set adminId(value) {
    if (!value) {
      this._cookieService.remove('otAdminId');
    } else {
      this._cookieService.put('otAdminId', value);
    }
  }

  get adminName() {
    return this._cookieService.get('otAdminName');
  }

  set adminName(value) {
    if (!value) {
      this._cookieService.remove('otAdminName');
    } else {
      this._cookieService.put('otAdminName', value);
    }
  }

  get userId() {
    return this._cookieService.get('otUserId');
  }

  set userId(value) {
    if (!value) {
      this._cookieService.remove('otUserId');
    } else {
      this._cookieService.put('otUserId', value);
    }
  }

  private refreshTokenCall: any;
  private _loggedInState = new BehaviorSubject<boolean>(false);
  private initialized = false;
  private cachedRequests: Array<HttpRequest<any>> = [];

  constructor(private _cookieService: CookieService, private http: HttpClient) {
    // this.resetSession();
  }

  public resetSession() {
    if (this.initialized) {
      return;
    }
    this._loggedInState.next(this.isLoggedIn);
    this._impersonateState.next(this.impersonate);
    this.initialized = true;
  }

  public logout() {
    this.killSession();
    window.location.href = `${environment.FULTONGRACE_URL}/logout/?next=/`;
  }

  public logoutFromImpersonate() {
    this.http
      .post<any>(ApiConfig.stopImpersonation, {})
      .pipe(
        tap(() => {
          this.impersonate = false;
        }),
        finalize(() => (window.location.href = environment.FULTONGRACE_URL))
      )
      .subscribe();
  }

  public getSettings() {
    this.http.get<any>(ApiConfig.getSettings).subscribe((data) => {
      this._announcementMessage.next(data.permanent_announcement);
    });
  }

  public killSession() {
    this.token = null;
    this.refreshToken = null;
    this.subdomain = null;
    this.impersonate = false;
    this.adminId = null;
    this.userId = null;
  }

  public doRefreshToken() {
    if (!this.refreshTokenCall) {
      const token = this.refreshToken;
      this.refreshToken = null;
      this.refreshTokenCall = this.http
        .post<any>(ApiConfig.tokenRefresh, { refresh: token })
        .pipe(
          tap((data: any) => {
            this.token = data.access;
            this.refreshToken = data.refresh;
            this._loggedInState.next(this.isLoggedIn);
          }),
          finalize(() => (this.refreshTokenCall = null))
        );
    }
    return this.refreshTokenCall;
  }
}
