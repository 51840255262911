<div class="container-fluid">
  <div class="row">
    <ng-container
      *ngTemplateOutlet="data?.customConfig?.template || defaultNotification; context: {data: data, processClose: processClose.bind(this)}"></ng-container>
  </div>
</div>


<ng-template #defaultNotification let-data="data" let-processClose="processClose">
  <div class="col-12 notify" [class.error]="data.type === 'error'" [class.warning]="data.type === 'warning'">
    <i class="fa fa-check" *ngIf="data.type === 'success'"></i>
    <i class="fa fa-exclamation-triangle" *ngIf="data.type === 'warning'" aria-hidden="true"></i>
    {{ data.text }}
    <button (click)="processClose()" class="close c-pointer"><i class="fa fa-close"></i></button>
  </div>
</ng-template>
